<script setup>
</script>

<template>
  <div id="info">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center w-100"
    >
      <div class="page-title d-flex justify-content-between pb-3">
        <div class="d-flex">
          <div class="header-like">Are You Ready to Trade Up?</div>
        </div>
        <div class="close-btn">
          <div @click="$emit('cancel')">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div class="text d-flex flex-column gap-3 w-100">
        <div class="fw-semibold">Dear Moxey Community,</div>
        <div>We've always believed in the transformative power of trade to help small businesses thrive, connect, and grow. Now, we're beyond excited to announce that something amazing is coming to Moxey!</div>
        <div>For 50 years, traditional barter exchanges have stuck to the same old playbook, creating a cycle of booms and busts at the expense of their members.</div>
        <div>It's time to break that cycle! At Moxey, we're charting a new course—one that puts your success first, values your contributions, and builds a dynamic trading economy for everyone.</div>
        <div>Get ready... because we're about to revolutionize trade and rock the barter world! Thank you for being part of this incredible journey and for helping us create something truly extraordinary. You're never going to believe what's next!</div>
        
        <div class="d-flex flex-column gap-0">
          <div class="fw-medium">Cheers!</div>  
          <div class="fw-medium">Your Moxey Team</div>
        </div>

        <div class="d-flex justify-content-end w-100 gap-1">
        <div @click="$emit('cancel')" class="d-flex align-items-center gap-1 cursor-pointer">
            Go Back <i class="fas fa-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

#info {
  width: 100%;
  min-height: 100%;
  background-color: white;
  color: var(--ms-dark);
  font-size: 18;
  line-height: 22px;
}
.sidebar-content {
  padding: 30px 0;
}

.text {
  width: 100%;
  padding: 0 30px;
}

.mx-dark {
  color: var(--mx-dark);
}

.title {
  font-weight: 600;
}

.page-title {
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: var(--mx-dark);
  padding: 0 30px;
}

.close-btn {
  cursor: pointer;
}

.mx-info-link {
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-grey);
}
</style>