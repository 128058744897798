<template>
  <div id="account">
    <div
      class="sidebar-content d-flex flex-column justify-content-start align-items-center top-section"
    >
      <div class="account-summary">
        <div v-if="!user" class="prelogin">
          <div class="d-flex justify-content-between">
            <div class="mx-logo">
              <img src="@/assets/moxey_dark_bg.svg" />
            </div>

            <div class="close-btn">
              <div @click="closeLeftBar">
                <i class="fas fa-times"></i>
              </div>
            </div>
          </div>

          <div class="login-btn text-center">
            <button
              v-if="!loggingIn"
              class="btn btn-outline-light btn-outline-light-custom"
              @click="$emit('showLogin')"
            >
              Sign In
            </button>
            <button
              v-if="loggingIn"
              class="btn btn-outline-light btn-outline-light-custom"
              disabled
            >
              <i class="fas fa-sync fa-spin"></i> Signing In
            </button>
          </div>
          <div class="sign-in-details my-4">
            <div class="my-3">Sign in to:</div>
            <div class="row my-3 align-items-center">
              <div class="col-auto fs-1">
                <span class="fa-layers fa-fw">
                  <i
                    class="fak fa-mx-sign-fat"
                    data-fa-transform="shrink-5"
                  ></i>
                  <i
                    class="fa-solid fa-arrow-right-arrow-left text-success"
                    data-fa-transform="shrink-10 down-4 right-4 "
                  ></i>
                </span>
              </div>
              <div class="col">Send and Receive Moxey</div>
            </div>
            <div class="row align-items-center my-4">
              <div class="col-auto fs-1">
                <span class="fa-layers fa-fw">
                  <i class="fa-solid fa-file" data-fa-transform="shrink-4"></i>
                  <i
                    class="fa-solid fa-magnifying-glass text-success"
                    data-fa-transform="shrink-11 down-1"
                  ></i>
                </span>
              </div>
              <div class="col">View Your Account Details</div>
            </div>

            <div class="divider" />
            <div class="fw-light my-4">
              Are you a new Moxey member? <strong>Welcome!</strong> Click here
              to claim your account.
            </div>

            <div class="text-center my-4">
              <mx-button
                btnClass="btn btn-success"
                @click="$emit('clickedShortcut', 'Register')"
                :height="48"
                :padX="30"
              >
                <i class="fas fa-star" />
                I'm a New Member
              </mx-button>
            </div>

            <div class="divider" />
            <div class="fw-light my-4">
              Got a Moxey Giftcard and need more information or to check its
              balance? Click here.
            </div>

            <div class="text-center my-4">
              <mx-button
                btnClass="btn btn-success"
                @click="launchGiftCards()"
                :height="48"
                :padX="30"
              >
                <i class="fas fa-gift-card" />
                Moxey Giftcards
              </mx-button>
            </div>

            <div class="divider" />
            <div class="fw-light my-4">
              Just need to quickly charge a Moxey card? Click here to launch
              Moxey Scan to Charge.
            </div>

            <div class="text-center">
              <mx-button
                btnClass="btn btn-success"
                @click="(e) => launchVPOS(e)"
                :height="48"
                :padX="30"
              >
                <span class="fa-layers fa-fw">
                  <i
                    class="fa-solid fa-credit-card-blank"
                    data-fa-transform=""
                  ></i>
                  <i
                    class="fak fa-mx-sign-fat mx-dark"
                    data-fa-transform="shrink-10 up-3 right-6"
                  ></i>
                </span>
                Charge Moxey Card
              </mx-button>
            </div>
          </div>
        </div>
        <div v-else-if="user">
          <account-header
            @close-clicked="closeLeftBar"
            @showVC="$emit('showVC')"
          />
          <div class="d-flex justify-content-around mt-4">
            <div
              @click="if (user) $emit('clickedShortcut', 'SendMx');"
              class="cursor-pointer d-flex flex-column justify-content-center align-items-center quick-button"
            >
              <div
                class="bg-success rounded-circle d-flex align-items-center justify-content-center"
                :class="{ deemph: !user }"
              >
                <div class="">
                  <span class="fa-layers fa-fw">
                    <i
                      class="fak fa-mx-sign-fat"
                      data-fa-transform="shrink-5 up-3"
                    ></i>
                    <i
                      class="fa-solid fa-share"
                      data-fa-transform="shrink-6 down-5 left-2 flip-v"
                    ></i>
                  </span>
                </div>
              </div>

              <div class="action-text mt-2 d-flex justify-content-center">
                <div>Send Moxey</div>
              </div>
            </div>

            <div
              @click="if (user) $emit('clickedShortcut', 'ChargeCard');"
              class="cursor-pointer d-flex flex-column justify-content-center align-items-center quick-button"
            >
              <div
                class="bg-success rounded-circle d-flex align-items-center justify-content-center"
                :class="{ deemph: !user }"
              >
                <div class="">
                  <span class="fa-layers fa-fw">
                    <i
                      class="fa-solid fa-credit-card-blank"
                      data-fa-transform=""
                    ></i>
                    <i
                      class="fak fa-mx-sign-fat mx-dark"
                      data-fa-transform="shrink-10 up-3 right-6"
                    ></i>
                  </span>
                </div>
              </div>
              <div class="action-text mt-2 d-flex justify-content-center">
                <div>Charge Card</div>
              </div>
            </div>

            <div
              @click="if (user) $emit('clickedShortcut', 'LoadCRA');"
              class="cursor-pointer d-flex flex-column justify-content-center align-items-center quick-button"
            >
              <div
                class="bg-success rounded-circle d-flex align-items-center justify-content-center"
                :class="{ deemph: !user }"
              >
                <div class="">
                  <span class="fa-layers fa-fw">
                    <i
                      class="fa-light fa-arrow-rotate-right"
                      data-fa-transform="grow-2 rotate-90"
                    ></i>
                    <i
                      class="fa-solid fa-dollar-sign"
                      data-fa-transform="shrink-8"
                    ></i>
                  </span>
                </div>
              </div>

              <div class="action-text mt-2 d-flex justify-content-center">
                <div>Reload CRA</div>
              </div>
            </div>
          </div>

          <div class="sticky-top bg-holder">
            <div class="d-flex justify-content-center">
              <div
                class="btn-group mt-4"
                role="group"
                aria-label="Payment type toggle"
              >
                <input
                  type="radio"
                  class="btn-check"
                  name="mode"
                  id="act"
                  autocomplete="off"
                  v-model="mode"
                  value="act"
                />
                <label
                  class="btn btn-sm btn-outline-light btn-outline-light-custom"
                  for="act"
                  ><i
                    class="fak fa-mx-sign-fat"
                    data-fa-transform="shrink-3"
                    data-fa-mask="fas fa-circle"
                  ></i>
                  My Account</label
                >

                <input
                  type="radio"
                  class="btn-check"
                  name="btnradio"
                  id="menu"
                  autocomplete="off"
                  v-model="mode"
                  value="menu"
                />
                <label
                  class="btn btn-sm btn-outline-light btn-outline-light-custom"
                  for="menu"
                  ><i class="fas fa-ellipsis"></i> More</label
                >
              </div>
            </div>
          </div>
          <goodbye />
          <div class="pane-holder">
            <div class="panes d-flex" :class="{ main: mode === 'menu' }">
              <div class="pane" :class="{ off: mode === 'menu' }">
                <account-summary
                  :user="user"
                  @close-clicked="closeLeftBar"
                  @clicked-shortcut="(s) => $emit('clickedShortcut', s)"
                />
                <div class="divider"></div>

                <div
                  class="action-button"
                  :class="{ deemph: !user }"
                  @click="
                    () => {
                      $router.push(`/account`);
                      closeLeftBar();
                    }
                  "
                >
                  <div class="action-icon">
                    <span class="fa-layers fa-fw">
                      <i
                        class="fa-solid fa-file-magnifying-glass"
                        data-fa-transform=""
                      ></i>
                    </span>
                  </div>
                  <div class="action-text">Account Details</div>
                </div>

                <div class="divider"></div>

                <div
                  class="action-button"
                  :class="{ deemph: !user }"
                  @click="doLogout"
                >
                  <div class="action-icon">
                    <span class="fa-layers fa-fw">
                      <i class="fa-solid fa-right-from-bracket"></i>
                      <!-- <span class="fa-layers-counter bg-danger" data-da-transform="grow-2">9+</span> -->
                    </span>
                  </div>
                  <div class="action-text">Sign Out</div>
                </div>
              </div>
              <div class="pane" :class="{ off: mode === 'act' }">
                <div
                  class="action-button"
                  :class="{ deemph: !user }"
                  @click="if (user) $emit('clickedShortcut', 'SendMx');"
                >
                  <div class="action-icon">
                    <span class="fa-layers fa-fw">
                      <i
                        class="fak fa-mx-sign-fat"
                        data-fa-transform="shrink-5 up-3 right-2"
                      ></i>
                      <i
                        class="fa-solid fa-share"
                        data-fa-transform="shrink-6 down-5 flip-v"
                      ></i>
                    </span>
                  </div>
                  <div class="action-text">Send Moxey</div>
                </div>

                <div
                  class="action-button"
                  @click="
                    (e) => {
                      if (user) {
                        $emit('clickedShortcut', 'ChargeCard');
                      } else {
                        launchVPOS(e);
                      }
                    }
                  "
                >
                  <div class="action-icon">
                    <span class="fa-layers fa-fw">
                      <i
                        class="fa-solid fa-credit-card-blank"
                        data-fa-transform=""
                      ></i>
                      <i
                        class="fak fa-mx-sign-fat mx-dark"
                        data-fa-transform="shrink-10 up-3 right-6"
                      ></i>
                    </span>
                  </div>
                  <div class="action-text">Charge Moxey Card</div>
                </div>

                <div
                  class="action-button"
                  :class="{ deemph: !user }"
                  @click="if (user) $emit('clickedShortcut', 'RequestMx');"
                >
                  <div class="action-icon">
                    <span class="fa-layers fa-fw">
                      <i
                        class="fak fa-mx-sign-fat"
                        data-fa-transform="shrink-5 up-1 right-2"
                      ></i>
                      <i
                        class="fa-solid fa-hand-holding"
                        data-fa-transform="shrink-5 down-3 left-1"
                      ></i>
                    </span>
                  </div>
                  <div class="action-text">Request Moxey</div>
                </div>

                <div
                  class="action-button"
                  :class="{ deemph: !user }"
                  @click="if (user) $emit('clickedShortcut', 'LoadCRA');"
                >
                  <div class="action-icon">
                    <span class="fa-layers fa-fw">
                      <i
                        class="fa-light fa-arrow-rotate-right"
                        data-fa-transform="grow-2 rotate-90"
                      ></i>
                      <i
                        class="fa-solid fa-dollar-sign"
                        data-fa-transform="shrink-8"
                      ></i>
                    </span>
                  </div>
                  <div class="action-text">Load Cash Reserve</div>
                </div>
                <div class="divider"></div>

                <div
                  v-if="user  && inviteExchanges.includes(user?.profile?.exchangeId) && (user.invitesLeft || user.invitesLeft === 0)"
                  class="action-button"
                  :class="{ deemph: !user }"
                  @click="if (user) $emit('clickedShortcut', 'Invite');"
                >
                  <div class="action-icon">
                    <span class="fa-layers fa-fw">
                      <i class="fa-solid fa-user-plus"></i>
                    </span>
                  </div>
                  <div class="action-text">
                    <div class="text">Invite and Earn</div>
                    <div
                      class="alert"
                      v-if="user?.invitesLeft || user?.invitesLeft === 0"
                    >
                      {{ user.invitesLeft > 9 ? "9+" : user.invitesLeft || 0 }}
                    </div>
                  </div>
                </div>

                <div
                  class="action-button"
                  :class="{ deemph: !user }"
                  @click="
                    () => {
                      $router.push(`/notifications`);
                      closeLeftBar();
                    }
                  "
                >
                  <div class="action-icon">
                    <span class="fa-layers fa-fw">
                      <i class="fa-solid fa-envelope"></i>
                    </span>
                  </div>
                  <div class="action-text">
                    <div class="text">My Notifications</div>
                    <div
                      class="alert"
                      v-if="user?.contact?.pendingNotifications > 0"
                    >
                      {{
                        user?.contact?.pendingNotifications > 9
                          ? "9+"
                          : user?.contact?.pendingNotifications
                      }}
                    </div>
                  </div>
                </div>

                <div
                  class="divider"
                  v-if="user && user.isElevated && !isPartial"
                ></div>

                <div
                  class="action-button"
                  v-if="user && user.isElevated"
                  @click="goToAdmin"
                >
                  <div class="action-icon">
                    <span class="fa-layers fa-fw">
                      <i class="fa-solid fa-unicorn"></i>
                      <!-- <span class="fa-layers-counter bg-danger" data-da-transform="grow-2">9+</span> -->
                    </span>
                  </div>
                  <div class="action-text">Moxey Admin</div>
                </div>

                <div class="divider" v-if="false"></div>

                <div class="action-button" @click="goToGiftCard">
                  <div class="action-icon">
                    <span class="fa-layers fa-fw">
                      <i class="fa-solid fa-gift-card"></i>
                      <!-- <span class="fa-layers-counter bg-danger" data-da-transform="grow-2">9+</span> -->
                    </span>
                  </div>
                  <div class="action-text">Moxey Gift Cards</div>
                </div>
                <div class="divider"></div>

                <div
                  class="action-button"
                  :class="{ deemph: !user }"
                  @click="
                    () => {
                      $router.push(`/account`);
                      closeLeftBar();
                    }
                  "
                >
                  <div class="action-icon">
                    <span class="fa-layers fa-fw">
                      <i
                        class="fa-solid fa-file-magnifying-glass"
                        data-fa-transform=""
                      ></i>
                    </span>
                  </div>
                  <div class="action-text">Account Details</div>
                </div>

                <!--
                <div class="divider"></div>


                <div class="action-button" @click="launchTour">
                  <div class="action-icon">
                    <span class="fa-layers fa-fw">
                      <i class="fa-solid fa-play"></i>
                    </span>
                  </div>
                  <div class="action-text">View the Tour</div>
                </div>
                -->

                <div
                  class="divider"
                  v-if="user && user.isElevated && !isPartial"
                ></div>

                <div
                  class="action-button"
                  :class="{ deemph: !user }"
                  @click="doLogout"
                >
                  <div class="action-icon">
                    <span class="fa-layers fa-fw">
                      <i class="fa-solid fa-right-from-bracket"></i>
                      <!-- <span class="fa-layers-counter bg-danger" data-da-transform="grow-2">9+</span> -->
                    </span>
                  </div>
                  <div class="action-text">Sign Out</div>
                </div>

                <div class="text-center tnc-block my-3">
                  <span class="tnc" @click="loadTNC"
                    >Moxey Terms and Conditions</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AccountSummary from "@/components/CoreApp/User/Shared/AccountSummary.vue";
import AccountHeader from "@/components/CoreApp/User/Shared/AccountHeader.vue";
import MxButton from "../../Shared/MxButton.vue";
import Goodbye from "../../Shared/Goodbye.vue";

export default {
  name: "Account",
  components: {
    AccountSummary,
    AccountHeader,
    MxButton,
    Goodbye,
  },
  data() {
    return {
      mode: "act",
    };
  },
  props: {},
  watch: {
    mode() {
      const sb = document.getElementById("left-bar");
      const pos = sb.scrollTop;
      sb.scroll(0, pos > 85 ? 85 : pos);
    },
  },
  computed: {
    isPartial() {
      return this.user && this.user.profile.isPartialMember;
    },
    ...mapState({
      showLeftBar: (state) => state.app.all.showLeftBar,
      user: (state) => state.app.all.user,
      loggingIn: (state) => state.app.all.loggingIn,
      moxeyPayPOSCode: (state) => state.app.all.moxeyPayPOSCode,
      moxeyPayContactId: (state) => state.app.all.moxeyPayContactId,
      inviteExchanges: (state) => state.app.all.inviteExchanges,
    }),
  },
  mounted() {},
  methods: {
    ...mapActions({
      updateAppStore: "app/updateAppStore",
    }),
    closeLeftBar() {
      this.updateAppStore({
        showLeftBar: false,
      });
    },
    doLogout() {
      if (this.user) {
        this.$emit("cancelLogin");
        if (this.$route.meta.checkLoggedIn) this.$router.push("/");
        setTimeout(() => {
          this.logout();
        }, 200);
      }
    },
    goToAdmin() {
      this.closeLeftBar();
      this.$router.push("/admin");
    },
    goToGiftCard() {
      this.closeLeftBar();
      this.$router.push("/giftcards");
    },
    loadTNC() {
      const routeData = this.$router.resolve({ name: "Terms and Conditions" });
      console.log(routeData);
      window.open(routeData.href, "_blank");
    },
    launchTour() {
      this.updateAppStore({
        showWalkthroughModal: true,
      });
    },
    launchVPOS() {
      this.$router.push(
        `/mpos/${this.moxeyPayPOSCode || "CODE_REQUIRED"}${
          this.moxeyPayContactId ? `/${this.moxeyPayContactId}` : ""
        }`
      );
      this.closeLeftBar();
    },
    launchGiftCards() {
      this.$router.push(`/giftcards`);
      this.closeLeftBar();
    },
    ...mapActions({
      updateAppStore: "app/updateAppStore",
      logout: "app/logout",
    }),
  },
  beforeMount() {},
};
</script>

<style scoped>
#account {
  width: 100%;
  min-height: 100%;
  color: var(--mx-white);
  font-size: 30px;
  line-height: 30px;
  background-color: var(--mx-black);
  background-image: url("../../../assets/xo.svg");
  background-position: 7px 5px;
  background-size: 40px;
}

.top-section {
  background-color: var(--mx-black);
  background-image: url("../../../assets/xo.svg");
  background-position: 7px 5px;
  background-size: 40px;
}

.sidebar-content {
  padding: 30px 0 0 0;
}

.account-summary {
  width: calc(100% - 60px);
  margin: 0;
}

.mx-logo {
  width: 200px;
}

.mx-logo img {
  width: 100%;
}

.btn-outline-light-custom {
  color: var(--mx-white);
  border-color: var(--mx-white);
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  padding: 15px 0;
  border-radius: 12px;
  width: 200px;
}

.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active,
.btn-outline-light:hover {
  color: var(--mx-dark);
  background-color: var(--mx-white);
}

.close-btn div {
  cursor: pointer;
}

.member-name div {
  font-size: 36px;
  line-height: 40px;
  margin: 20px 0 0;
}

.member-name .sub {
  font-size: 18px;
  line-height: 22px;
}

.divider {
  width: calc(100% - 60px);
  background-color: var(--mx-white);
  height: 1px;
  margin: 10px 15px;
}

.action-button {
  width: calc(100% - 30px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  margin: 0 0;
  cursor: pointer;
  transition: background-color 0.1s ease-in;
  font-size: 30px;
  line-height: 30px;
}

.action-button:not(.deemph):hover {
  background-color: #00af6c47;
  transition: background-color 0.1s ease-out;
}

.deemph.action-button {
  opacity: 0.5;
  cursor: not-allowed;
}

.action-button:last-child {
  margin: 15px 0;
}

.pane .action-button {
  width: 100%;
  padding: 10px;
}

.pane .divider {
  width: calc(100%);
  margin: 10px 0;
}

.action-icon {
  font-size: 20px;
  margin: 0 15px 0 0;
}

.action-text {
  font-size: 20px;
  line-height: 20px;
  font-weight: 300;
  position: relative;
}

.mx-dark {
  color: var(--mx-dark);
}

.badge {
  font-size: 10px;
  color: black;
  transform: rotate(7deg) translate(5px, -5px);
}

.fa-layers-counter {
  transform: scale(0.4, 0.4) translate(40%, -20%);
}

.divider div {
  width: 100%;
  position: relative;
  overflow: visible;
  display: flex;
  justify-content: center;
}

.coming-soon {
  transform: rotate(-60deg);
  position: relative;
  white-space: nowrap;
  font-size: 72px;
  opacity: 1;
  z-index: 10;
  top: 300px;
}

.tnc-block {
  width: 100%;
}

.tnc {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  cursor: pointer;
  border-bottom: 1px dotted var(--mx-white);
}

.action-text .alert {
  position: absolute;
  top: -10px;
  left: -50px;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  background-color: var(--bs-danger);
  padding: 1.5px 3px;
  border-radius: 20px;
}

.pane-holder {
  width: calc(100% + 60px);
  overflow-x: hidden;
  background-color: var(--mx-dark);
  margin: -50px -30px 0;
  padding: 30px 0 79px;
  min-height: calc(100vh - 160px);
}

.panes {
  transform: translateX(0%);
  transition-property: transform, max-height;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}

.panes.main {
  transform: translateX(-100%);
  transition-property: transform, max-height;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}

.pane {
  overflow: hidden;
  min-width: calc(100%);
  padding: 0 30px;
}

.pane.off {
  height: 0;
  max-height: 0;
}

.bg-holder {
  width: calc(100% + 60px);
  margin: 0 -30px -60px;
  background-color: var(--mx-dark);
  padding: 0 15px 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  background-color: var(--mx-black);
  background-image: url("../../../assets/xo.svg");
  background-position: 7px -37px;
  background-size: 40px;
  top: calc(100% - env(safe-area-inset-bottom, 0) - 78px);
}

.btn-group {
  max-width: calc(100%);
  margin-top: 15px;
}

.btn-group .btn {
  font-size: 16px;
  line-height: 16px;
  padding: 10px 5px;
}

.sign-in-details {
  font-size: 18px;
  line-height: 22px;
}

.sign-in-details .divider {
  margin: 0;
  width: 100%;
}

.quick-button > .bg-success {
  height: 80px;
  width: 80px;
  font-size: 1.3em;
}

.quick-button .action-text {
  font-size: 0.5em;
  white-space: nowrap;
  width: 0;
}

.account-header {
  width: calc(100% + 60px);
  margin: -19px -30px;
  padding: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

@media (min-width: 1400px) {
  .bg-holder {
    border-top: unset;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    top: 0;
  }

  .pane-holder {
    margin: 60px -30px 0;
  }

  .account-header {
    width: unset;
    margin: unset;
    padding: unset;
    border-bottom: unset;
  }
}
</style>
